export const columns = [
    {
        title: '审批状态',
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' }
    },
    {
        title: '项目名称',
        dataIndex: 'process_form[项目名称].value',
        scopedSlots: { customRender: 'name' }
    },
    {
        title: '成交房号',
        dataIndex: 'process_form[成交房号].value',
        scopedSlots: { customRender: 'room' }
    },
    {
        title: '购房/承租方',
        dataIndex: 'process_form[购房/承租方].value',
        scopedSlots: { customRender: 'client' }
    },
    {
        title: '认购时间',
        dataIndex: 'process_form[认购时间].value',
        scopedSlots: { customRender: 'order' }
    },
    {
        title: '签约日期',
        dataIndex: 'process_form[签约日期].value',
        scopedSlots: { customRender: 'sign' }
    },
    {
        title: '业绩归属日期',
        dataIndex: 'process_form[业绩归属日期].value',
        scopedSlots: { customRender: 'deal' }
    },
    {
        title: '我的业绩',
        dataIndex: 'amount',
        scopedSlots: { customRender: 'amount' }
    },
    {
        title: '成单类型',
        dataIndex: 'sale_type',
        scopedSlots: { customRender: 'type' }
    },
    {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        fixed: 'right',
        scopedSlots: { customRender: 'action' }
    }
]

export const typeMap = {
    'main_seller': {
        status: 'success',
        text: '成交人'
    },
    'side_seller': {
        status: 'processing',
        text: '分单人'
    }
}

export const statusMap = {
    'RUNNING': {
        status: 'processing',
        color: '#87d068',
        text: '未完成'
    },
    'COMPLETED': {
        status: 'success',
        color: '#108ee9',
        text: '已完成'
    },
    'TERMINATED': {
        status: 'error',
        color: '#f50',
        text: '已终止'
    },
    undefined: {
        status: 'error',
        color: '#f50',
        text: '异常'
    }
}
