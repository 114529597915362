<template>
  <page-header-wrapper>
    <un-bind v-if="visible" />
    <div v-else>
      <analyze :analyzeData="analyzeData" />
      <a-card :bordered="false">
        <div class="table-page-search-wrapper">
          <a-form layout="inline">
            <a-row :gutter="48">
              <a-col :md="8" :sm="24">
                <a-form-item label="成单类型">
                  <a-select
                    allow-clear
                    :filter-option="filterOption"
                    class="inputLong"
                    placeholder="请选择"
                    v-model="queryParam.sell_type"
                    @change="typeChange"
                  >
                    <a-select-option value="main_seller">
                      成交人
                    </a-select-option>
                    <a-select-option value="side_seller">
                      分单人
                    </a-select-option>
                    <a-select-option value="">
                      全部
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="审批状态">
                  <a-select
                    allow-clear
                    :filter-option="filterOption"
                    class="inputLong"
                    placeholder="请选择"
                    v-model="queryParam.approve_status"
                    @change="statusChange"
                  >
                    <a-select-option value="completed">
                      已完成审批
                    </a-select-option>
                    <a-select-option value="uncompleted">
                      未完成审批
                    </a-select-option>
                    <a-select-option value="">
                      全部
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <template v-if="advanced">
                <a-col :md="8" :sm="24">
                  <a-form-item label="认购时间">
                    <a-range-picker v-model="orderTime" @change="orderChange" class="inputLong" />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="签约日期">
                    <a-range-picker v-model="signTime" @change="signChange" class="inputLong" />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="业绩归属日期">
                    <a-range-picker v-model="dealTime" @change="atributeChange" class="inputLong" />
                  </a-form-item>
                </a-col>
              </template>
              <a-col :md="(!advanced && 8) || 24" :sm="24">
                <span
                  class="table-page-search-submitButtons"
                  :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
                >
                  <a-button type="primary" icon="search" @click="reload">查询</a-button>
                  <a-button style="margin-left: 8px" icon="redo" @click="resetReload">重置</a-button>
                  <a @click="toggleAdvanced" style="margin-left: 8px">
                    {{ advanced ? '收起' : '展开' }}
                    <a-icon :type="advanced ? 'up' : 'down'" />
                  </a>
                </span>
              </a-col>
            </a-row>
          </a-form>
        </div>

        <div class="table-operator">
          <a-button type="primary" icon="download" @click="excelExport">导出</a-button>
        </div>

        <s-table
          ref="table"
          size="default"
          :rowKey="record => record.id"
          :columns="columns"
          :data="loadData"
          :alert="true"
          :totalCount="totalCount"
          :scroll="defaultScroll"
          :rowSelection="rowSelection"
          showPagination="auto"
        >
          <span slot="name" slot-scope="text">{{ text | textFilter }}</span>
          <span slot="room" slot-scope="text">{{ text | textFilter }}</span>
          <span slot="client" slot-scope="text">{{ text | textFilter }}</span>
          <span slot="order" slot-scope="text">{{ text | textFilter }}</span>
          <span slot="sign" slot-scope="text">{{ text | textFilter }}</span>
          <span slot="deal" slot-scope="text">{{ text | textFilter }}</span>
          <span slot="amount" slot-scope="text">{{ text | priceFilter }}</span>
          <span slot="type" slot-scope="text">
            <a-badge :text="text | typeTextFilter" :status="text | typeFilter" />
          </span>
          <span slot="status" slot-scope="text">
            <a-tag :color="text | statusTypeFilter">{{ text | statusFilter }}</a-tag>
          </span>
          <span slot="action" slot-scope="text, record">
            <template>
              <a @click="detail(record)">详情</a>
            </template>
          </span>
        </s-table>
      </a-card>
    </div>
    <detail-drawer :id="detailId" v-if="detailId" @close="detailId = null"></detail-drawer>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import { moxiInstances } from '@/api/moxi'
import { columns, statusMap, typeMap } from './js/index'
import UnBind from '../components/UnBind'
import Analyze from './components/Analyze'
import DetailDrawer from '../detail/detail-drawer.vue'

export default {
  components: {
    STable,
    Ellipsis,
    UnBind,
    DetailDrawer,
    Analyze
  },
  data() {
    return {
      noticeTitle: '财务开票',
      totalCount: 0,
      page: 0,
      storeKeys: {},
      storeRows: {},
      columns,
      dealTime: null,
      signTime: null,
      orderTime: null,
      advanced: false,
      queryParam: {},
      params: {},
      selectedRowKeys: [],
      selectedRows: [],
      analyzeData: {},
      loadData: parameter => {
        this.page = parameter.pageNo
        const param = {
          seller: this.dingUserId
        }
        const params = this.dParamFormat(parameter, param)
        Object.assign(params, this.queryParam)
        this.params = params
        return moxiInstances(this.dingUserId, params).then(res => {
          const result = this.dResFormat(res)
          this.analyzeData = res.data
          this.totalCount = result.totalCount
          return result
        })
      },
      detailId: null
    }
  },
  filters: {
    statusFilter(type) {
      return statusMap[type].text
    },
    statusTypeFilter(type) {
      return statusMap[type].color
    },
    typeFilter(type) {
      return typeMap[type].status
    },
    typeTextFilter(type) {
      return typeMap[type].text
    }
  },
  computed: {
    serial() {
      return this.$store.state.env.serial
    },
    dingUserId() {
      return this.$store.state.dingUser.data.user_id
    },
    dingUser() {
      return this.$store.state.dingUser.data
    },
    visible() {
      return Object.keys(this.dingUser).length === 0
    },
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: this.onSelectChange,
        getCheckboxProps: record => ({
          props: {
            defaultChecked: this.fetchStoreKeys(record.id)
          }
        })
      }
    }
  },
  methods: {
    reload() {
      this.tableHome()
      this.$refs.table.refresh()
    },
    resetReload() {
      this.queryParam = {}
      const param = {
        dealTime: null,
        orderTime: null,
        signTime: null
      }
      Object.assign(this, param)
      this.reload()
    },
    excelExport() {
      const params = {
        authorization: this.dingUserId,
        page_name: '我的业绩'
      }
      if (this.storeRows.length !== 0) {
        params.instance_ids = this.fetchMapArr(this.storeRows).map(item => {
          return item.process_instance_id
        }).join(',')
      }
      Object.assign(params, this.queryParam)
      window.location.href = this.requestFormat(this.serial + '/api/v1/dingtalk/sales/instance/export/', params)
    },
    detail(record) {
      // const query = {
      //   id: record.process_instance_id
      // }
      // this.navTo('/member/mine/detail', query)
      const id = record.process_instance_id;
      this.detailId = id
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.storeKeys[this.page] = selectedRowKeys
      this.storeRows[this.page] = selectedRows
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    },
    fetchStoreKeys(id) {
      const newArr = this.fetchMapArr(this.storeKeys)
      return newArr.includes(id)
    },
    fetchMapArr(map) {
      let key = ''
      const arr = []
      for (key in map) {
        arr.push(map[key])
      }
      const newArr = []
      arr.forEach(item => {
        item.forEach(key => {
          newArr.push(key)
        })
      })
      return newArr
    },
    statusChange(value) {
      if (value === undefined) {
        delete this.queryParam.approve_status
      }
    },
    typeChange(value) {
      if (value === undefined) {
        delete this.queryParam.sell_type
      }
    },
    orderChange(value) {
      if (value.length === 0) {
        delete this.queryParam.order_start_time
        delete this.queryParam.order_stop_time
      } else {
        this.queryParam.order_start_time = this.dateFormat(value[0]._d, 'YYYY-MM-DD 00:00:00')
        this.queryParam.order_stop_time = this.dateFormat(value[1]._d, 'YYYY-MM-DD 00:00:00')
      }
    },
    signChange(value) {
      if (value.length === 0) {
        delete this.queryParam.sign_start_time
        delete this.queryParam.sign_stop_time
      } else {
        this.queryParam.sign_start_time = this.dateFormat(value[0]._d, 'YYYY-MM-DD 00:00:00')
        this.queryParam.sign_stop_time = this.dateFormat(value[1]._d, 'YYYY-MM-DD 00:00:00')
      }
    },
    atributeChange(value) {
      if (value.length === 0) {
        delete this.queryParam.attribution_start_time
        delete this.queryParam.attribution_stop_time
      } else {
        this.queryParam.attribution_start_time = this.dateFormat(value[0]._d, 'YYYY-MM-DD 00:00:00')
        this.queryParam.attribution_stop_time = this.dateFormat(value[1]._d, 'YYYY-MM-DD 00:00:00')
      }
    }
  }
}
</script>
